
<template>
    <div class="maintain">
        <div class="content_box">
            <table>
                <tbody>
                    <tr>
                        <th colspan="4" class="tHeader">
                            运输合同信息表
                        </th>
                    </tr>
                    <tr>
                        <th>合同编号：</th>
                        <td>{{ formData.contract.contract_no }}</td>
                        <th>合同名称：</th>
                        <td>{{ formData.contract.contract_name }}</td>
                    </tr>
                    <tr>
                        <th>供货商：</th>
                        <td colspan="3">
                            {{ formData.contract.supplier_name }}
                        </td>
                    </tr>
                    <tr>
                        <th colspan="4" class="tHeaderBtn">
                            <h4 class="el_left">
                                物料信息表
                            </h4>
                            <el-button type="primary" class="el_right" @click="addForm">
                                添加
                            </el-button>
                        </th>
                    </tr>
                    <tr>
                        <td colspan="4" class="tdStyle">
                            <template v-for="(item, index) in formData.transports">
                                <table :key="index" :class="{'invalid':item.is_enabled == 0}">
                                    <tbody v-if="item.rootid">
                                        <tr>
                                            <th>起始地：</th>
                                            <td>{{ item.source_name }}</td>
                                            <th>到货站点：</th>
                                            <td>{{ item.station_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>供应商名称：</th>
                                            <td>{{ item.supplier_name }}</td>
                                            <th>物料名称：</th>
                                            <td>{{ item.type_name }}</td>
                                        </tr>
                                        <tr>
                                            <th>计量单位：</th>
                                            <td class="tdStyle">
                                                <el-select
                                                    v-model="item.measure_unit"
                                                    placeholder="请选择计量单位"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-option label="吨" value="吨"></el-option>
                                                    <el-option label="千克" value="千克"></el-option>
                                                    <el-option label="方" value="方"></el-option>
                                                </el-select>
                                            </td>
                                            <th>运输单价：</th>
                                            <td>{{ item.transport_price }}</td>
                                        </tr>
                                        <tr>
                                            <th>税率（%）：</th>
                                            <td>{{ item.transport_rate }}</td>
                                            <th>运输方式：</th>
                                            <td>
                                                <el-radio-group v-model="item.transport_type" @change="modify(index)">
                                                    <el-radio :label="0">
                                                        散装
                                                    </el-radio>
                                                    <el-radio :label="1">
                                                        罐装
                                                    </el-radio>
                                                </el-radio-group>
                                            </td>
                                        </tr>
                                        <tr class="state">
                                            <th>
                                                状态
                                            </th>
                                            <td>
                                                <el-radio-group v-model="item.is_enabled" @change="modify(index)">
                                                    <el-radio :label="1">
                                                        有效
                                                    </el-radio>
                                                    <el-radio :label="0">
                                                        失效
                                                    </el-radio>
                                                </el-radio-group>
                                            </td>
                                            <template v-if="item.is_enabled === 1">
                                                <th>
                                                    生效时间
                                                </th>
                                                <td class="tdStyle">
                                                    <el-date-picker
                                                        v-model="item.effective_date"
                                                        type="datetime"
                                                        placeholder="选择生效时间"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        @change="modify(index)"
                                                    >
                                                    </el-date-picker>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <th>
                                                    失效时间
                                                </th>
                                                <td class="tdStyle">
                                                    <el-date-picker
                                                        v-model="item.cutoff_date"
                                                        type="datetime"
                                                        placeholder="选择失效时间"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        @change="modify(index)"
                                                    >
                                                    </el-date-picker>
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <th>起始地：</th>
                                            <td class="tdStyle" @click="selectSource(index, item)">
                                                <el-input
                                                    v-model="item.source_name"
                                                    readonly
                                                    placeholder="请选择起始地"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-button slot="append">
                                                        选择
                                                    </el-button>
                                                </el-input>
                                            </td>
                                            <th>到货站点：</th>
                                            <td class="tdStyle" @click="selectStation(index, item)">
                                                <el-input
                                                    v-model="item.station_name"
                                                    readonly
                                                    placeholder="请选择到货站点"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-button slot="append">
                                                        选择
                                                    </el-button>
                                                </el-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>供应商名称：</th>
                                            <td class="tdStyle" @click="selectSupplier(index, item)">
                                                <el-input
                                                    v-model="item.supplier_name"
                                                    readonly
                                                    placeholder="请选择供应商名称"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-button slot="append">
                                                        选择
                                                    </el-button>
                                                </el-input>
                                            </td>
                                            <th>物料名称：</th>
                                            <td class="tdStyle" @click="selectType(index, item)">
                                                <el-input
                                                    v-model="item.type_name"
                                                    readonly
                                                    placeholder="请选择物料名称"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-button slot="append">
                                                        选择
                                                    </el-button>
                                                </el-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>计量单位：</th>
                                            <td class="tdStyle">
                                                <el-select
                                                    v-model="item.measure_unit"
                                                    placeholder="请选择计量单位"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                >
                                                    <el-option label="吨" value="吨"></el-option>
                                                    <el-option label="千克" value="千克"></el-option>
                                                    <el-option label="方" value="方"></el-option>
                                                </el-select>
                                            </td>
                                            <th>运输单价：</th>
                                            <td class="tdStyle">
                                                <el-input
                                                    type="number"
                                                    v-model="item.transport_price"
                                                    placeholder="请输入运输单价"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                ></el-input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>税率（%）：</th>
                                            <td class="tdStyle">
                                                <el-input
                                                    type="number"
                                                    v-model="item.transport_rate"
                                                    placeholder="请输入税率"
                                                    @change="modify(index)"
                                                    :disabled="item.is_enabled == 0"
                                                ></el-input>
                                            </td>
                                            <th>运输方式：</th>
                                            <td>
                                                <el-radio-group v-model="item.transport_type" @change="modify(index)">
                                                    <el-radio :label="0">
                                                        散装
                                                    </el-radio>
                                                    <el-radio :label="1">
                                                        罐装
                                                    </el-radio>
                                                </el-radio-group>
                                            </td>
                                        </tr>
                                        <tr class="state">
                                            <th>
                                                状态
                                            </th>
                                            <td>
                                                <el-radio-group v-model="item.is_enabled" @change="modify(index)">
                                                    <el-radio :label="1">
                                                        有效
                                                    </el-radio>
                                                    <el-radio :label="0">
                                                        失效
                                                    </el-radio>
                                                </el-radio-group>
                                            </td>
                                            <template v-if="item.is_enabled === 1">
                                                <th>
                                                    生效时间
                                                </th>
                                                <td class="tdStyle">
                                                    <el-date-picker
                                                        v-model="item.effective_date"
                                                        type="datetime"
                                                        placeholder="选择生效时间"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        @change="modify(index)"
                                                    >
                                                    </el-date-picker>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <th>
                                                    失效时间
                                                </th>
                                                <td class="tdStyle">
                                                    <el-date-picker
                                                        v-model="item.cutoff_date"
                                                        type="datetime"
                                                        placeholder="选择失效时间"
                                                        value-format="yyyy-MM-dd HH:mm:ss"
                                                        @change="modify(index)"
                                                    >
                                                    </el-date-picker>
                                                </td>
                                            </template>
                                        </tr>
                                        <tr>
                                            <th colspan="4" class="tHeaderBtn">
                                                <el-button type="danger" class="el_right" @click="deleteFormList(item, index)">
                                                    删除
                                                </el-button>
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="preservation">
                保存
            </el-button>
            <el-button type="primary" @click="versionConfirmation">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
import store from '@/store';

export default {
    // 原材料合同台账维护
    name: 'maintain',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            // 合同id
            ciid: '',
            // 初始表单数据
            formData: {
                contract: {
                    contract_no: '',
                    contract_name: '',
                    supplier_name: '',
                },
                transports: [],
            },
            // 新增数据结构
            addFormData: {
                id: '',
                civid: '',
                ctid: '',
                ctcsid: '',
                rootid: '',
                ciid: '',
                contract_no: '',
                version_no: 0,
                transport_id: '',
                transport_name: '',
                transport_credit_code: '',
                effective_date: '',
                cutoff_date: '',
                is_enabled: 1,
                source_id: '',
                source_name: '',
                station_id: '',
                station_name: '',
                supplier_id: '',
                supplier_name: '',
                type_code: '',
                type_dic_code: '',
                type_name: '',
                transport_price: 0,
                transport_rate: 0,
                measure_unit: '',
                transport_type: 0,
                overweight_value: 0,
                overweight_transport_price: 0,
                ischanged: true,
            },
            // 当前选择下标
            fonmIndex: 0,
            // 是否以保存
            isPreservation: true,
        };
    },
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 关闭弹出层
        cancel() {
            this.$parent.hide();
        },
        // 初始化表单数据
        getInitData() {
            this.$axios
                .get(`/interfaceApi/procurement/contract/versions/transport/contract/getorcreateedit/${this.ciid}`)
                .then(res => {
                    this.formData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加
        addForm() {
            this.formData.transports.push(JSON.parse(JSON.stringify(this.addFormData)));
        },

        // 选择起始地
        selectSource(index, data) {
            if (data.is_enabled === 0) {
                return;
            }
            this.fonmIndex = index;
            this.$toast({
                title: true,
                text: '选择起始地',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ysht_xzhyd',
                        ViewCode: 'default',
                    },
                    selectCallback: this.confirmSelectSource,
                },
            });
        },
        // 选择货源地回调
        confirmSelectSource(data) {
            this.formData.transports[this.fonmIndex].source_name = data.name;
            this.formData.transports[this.fonmIndex].source_id = data.id;
        },
        // 选择场站
        selectStation(index, data) {
            if (data.is_enabled === 0) {
                return;
            }
            if (!data.source_id) {
                this.$message({
                    message: '请选择起始地！',
                    type: 'warning',
                });
                return;
            }
            this.fonmIndex = index;
            this.$toast({
                title: true,
                text: '选择到货站点',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ysht_xzcz_hydys',
                        QueryString: `source_ids=${data.source_id}`,
                        ViewCode: 'one',
                    },
                    selectCallback: this.confirmSelectStation,
                },
            });
        },
        // 选择场站回调
        confirmSelectStation(data) {
            this.formData.transports[this.fonmIndex].station_id = data.dept_id;
            this.formData.transports[this.fonmIndex].station_name = data.dept_name;
        },
        // 选择供应商
        selectSupplier(index, data) {
            if (data.is_enabled === 0) {
                return;
            }
            if (!data.source_id) {
                this.$message({
                    message: '请选择起始地！',
                    type: 'warning',
                });
                return;
            }
            this.fonmIndex = index;
            this.$toast({
                title: true,
                text: '选择供应商',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ysht_xzgys',
                        QueryString: `source_ids=${data.source_id}`,
                        ViewCode: 'default',
                    },
                    selectCallback: this.confirmSelectSupplier,
                },
            });
        },
        // 选择供应商回调
        confirmSelectSupplier(data) {
            this.formData.transports[this.fonmIndex].supplier_id = data.id;
            this.formData.transports[this.fonmIndex].supplier_name = data.name;
        },
        // 选择物料名称
        selectType(index, data) {
            if (data.is_enabled === 0) {
                return;
            }
            if (!data.source_id) {
                this.$message({
                    message: '请选择起始地！',
                    type: 'warning',
                });
                return;
            }
            if (!data.supplier_id) {
                this.$message({
                    message: '请选择供应商！',
                    type: 'warning',
                });
                return;
            }
            this.fonmIndex = index;
            this.$toast({
                title: true,
                text: '选择物料名称',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ysht_xzml',
                        QueryString: `source_ids=${data.source_id}&supplier_ids=${data.supplier_id}`,
                        ViewCode: 'one',
                    },
                    selectCallback: this.confirmSelectType,
                },
            });
        },
        // 选择物料名称回调
        confirmSelectType(data) {
            this.formData.transports[this.fonmIndex].type_code = data.type_code;
            this.formData.transports[this.fonmIndex].type_dic_code = data.type_dic_code;
            this.formData.transports[this.fonmIndex].type_name = data.type_name;
        },
        // 已修改
        modify(index) {
            this.formData.transports[index].ischanged = true;
        },
        // 删除
        deleteFormList(data, index) {
            if (data.id) {
                data.is_delete = 1;
                data.ischanged = true;
            } else {
                this.formData.transports.splice(index, 1);
            }
        },
        // 保存
        preservation() {
            this.$axios
                .post('/interfaceApi/procurement/contract/versions/transport/contract/version/save/' + this.formData.contract.id
                    , this.formData.transports)
                .then(res => {
                    this.formData = res;
                    this.isPreservation = true;
                    this.$message({
                        message: '保存成功！',
                        type: 'success',
                    });

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 确认
        versionConfirmation() {
            this.formData.transports.forEach(item => {
                if (item.ischanged) {
                    this.isPreservation = false;
                }
            });
            if (this.isPreservation === false) {
                this.$message({
                    message: '请先保存！',
                    type: 'warning',
                });
            } else {
                this.$axios
                    .get(`/interfaceApi/procurement/contract/versions/transport/contract/version/confirm/${this.formData.contract.id}`)
                    .then(() => {
                        this.$message({
                            message: '版本已确认！',
                            type: 'success',
                        });
                        this.$parent.hide();
                        store.state.currentOpenList.forEach(item => {
                            item.tableObj.updateTable();
                        });
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // 合同id
        this.ciid = this.extr.ciid;

        // 初始化表单数据
        this.getInitData();
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
};
</script>
<style lang='stylus'>
    .maintain
        height 100%
        padding 0.15rem
        .content_box
            width 100%
            height calc(100% - 0.6rem)
            padding-bottom 0.1rem
            overflow-y auto
            table
                width 100%
                border-collapse collapse
                th, td
                    height 0.42rem
                    text-align center
                    font-size 0.16rem
                    position relative
                    border 1px solid #D7D7D7
                    font-weight 400
                    text-align left
                th
                    color #002B94
                    background-color #F6F8FC
                    width 2rem
                    text-indent 0.2rem
                    &.tHeader
                        font-size 0.18rem
                        background #EDF0F5
                        text-align center
                    &.tHeaderBtn
                        h4
                            font-size 0.18rem
                            line-height 0.42rem
                        button
                            width 0.6rem
                            height 0.3rem
                            line-height 0.3rem
                            text-align center
                            padding 0
                            margin 0.06rem 0.1rem
                td
                    color #333
                    width 5rem
                    padding-left 0.2rem
                    &.tdStyle
                        padding-left 0
                    table
                        margin  0.1rem
                        width calc(100% - 0.2rem)
                        &:nth-of-type(even)
                            th,td
                                border 1px solid #D6CEC9
                            th
                                color #823802
                                background-color #FCF9F6
                        &.invalid
                            tr
                                opacity 0.5
                                &.state
                                    opacity 1
                    .el-input, .el-select
                        width 100% !important
                        height 0.42rem
                        font-size 0.16rem
                        .el-input__inner
                            height 0.42rem
                            line-height 0.42rem
        footer
            height 0.5rem
            text-align center
            margin-top 0.1rem
            button
                width 2rem
                margin 0 0.3rem
                height 0.42rem
                font-size 0.16rem
                line-height 0.42rem
                padding 0
</style>
